import React, { useState } from "react";
import { useRouter } from "next/router";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { BsFacebook } from "react-icons/bs";
import { BiErrorCircle, BiHide } from "react-icons/bi";

import { loginUser } from "../api/authApi";
import { REGEX } from "../utils/validations";

import styles from "../styles/login.module.css";
import { setUserData } from "../actions/userData";
import Success from "../components/Success";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { AiOutlineEye } from "react-icons/ai";
import Head from "next/head";
import Error from "../components/Error";
import { Config } from "../utils/config";
import { localization } from "../hooks/useLocalization";
import { findTamilCharacter } from "../utils/Helper";



const login = () => {
  // routes
  const router = useRouter();
  const dispatch = useDispatch();
  // states
  const [showPassword, setShowPassword] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formHandle, setFormHandle] = useState({
    email: "",
    password: "",
  });

  // form validation
  const formSchema = Yup.object().shape({
    password: Yup.string().required("Password is mandatory"),
    email: Yup.string()
      .matches(REGEX.email, "Not a valid Email Address")
      .required("Email is Field mandatory"),
  });

  // form handler
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { mutate, isError, isLoading, error } = useMutation(loginUser, {
    onSuccess: async (res) => {
      const Json = await res.json();
      if (Json?.jwt) {
        localStorage.setItem("user", JSON.stringify(Json?.user));
        localStorage.setItem("shaivamToken", JSON.stringify(Json?.jwt));
        dispatch(setUserData(Json?.user));
        defaultValuesAfterLogin();
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
          router.push("/");
        }, 3000);
      }
      if (res.status !== 200) {
        setShowErrorMessage(true);
        setErrorMessage(JSON.stringify(Json?.error?.message));
        return;
      }
    },
  });

  function defaultValuesAfterLogin() {
    reset({
      email: "",
      password: "",
    });
    setFormHandle({
      email: "",
      password: "",
    });
  }

  const TextMessage = localization()


  const submit = (data, e) => {
    e.preventDefault();

    mutate(data);
  };

  
 

  return (
    <>
      <Head>
        <title>Shaivam.org - Login Page</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="description"
          content="Page for handling Login for Shaivam.org site"
        />
         <link rel="icon" href="/assests/icons/logo.png" />
      </Head>

      <div
        className={styles.loginWrapper}
        style={{ display: showSuccessMessage ? "none" : "flex" }}
      >
        <div className={styles.login}>
          <div className={styles.head}>
            <h1>{TextMessage?.LoginPage?.Heading}</h1>

            <div className={styles.pWrapper}>
              <div>
                <p className={findTamilCharacter(TextMessage?.LoginPage?.Heading)? styles.tamilP : ""}>
                  <button></button> {TextMessage?.LoginPage?.Points1?.one}
                </p>
              </div>
              <div>
                <p className={findTamilCharacter(TextMessage?.LoginPage?.Heading)? styles.tamilP : ""}>
                  {" "}
                  <button></button> {TextMessage?.LoginPage?.Points1?.two}
                </p>
              </div>
            </div>
            <div>
              <p className={findTamilCharacter(TextMessage?.LoginPage?.Heading)? styles.tamilP : ""}>
                {" "}
                <button></button> {TextMessage?.LoginPage?.Points1?.three}
              </p>
            </div>
          </div>

          <form
            onSubmit={handleSubmit(submit)}
            action=""
            className={styles.form}
          >
            <div className={styles.input}>
              <p>{TextMessage?.LoginPage?.Fields?.email}</p>
              <input
                {...register("email")}
                type="text"
                value={formHandle.email?.trim()}
                onInput={(e) =>
                  setFormHandle({ ...formHandle, email: e.target.value })
                }
                required
                // autoComplete="off"
              />
              {errors?.email && (
                <span>
                  <BiErrorCircle /> {errors?.email?.message}
                </span>
              )}
            </div>
            <div className={`${styles.inputPassword} ${styles.input}`}>
              <p> {TextMessage?.LoginPage?.Fields?.password}</p>
              <input
                {...register("password")}
                type={showPassword ? "text" : "password"}
                value={formHandle.password?.trim()}
                onInput={(e) =>
                  setFormHandle({ ...formHandle, password: e.target.value })
                }
                required
                // autoComplete="off"
              />
              <span
                className={styles.showHidBtn}
                onClick={() => setShowPassword(!showPassword)}
              >
                {" "}
                {showPassword ? (
                  <AiOutlineEye color="black" />
                ) : (
                  <BiHide color="black" />
                )}
              </span>
              {errors?.password && (
                <span>
                  <BiErrorCircle /> {errors?.password?.message}
                </span>
              )}
            </div>
            <div className={styles.btn}>
              <button
                type="submit"
                disabled={errors?.password || errors?.email ? true : false}
                style={{ cursor: "pointer" }}
              >
                Login
              </button>
              <div className={styles.forgot}>
                <p className={findTamilCharacter(TextMessage?.LoginPage?.Heading)? styles.Tamilp : ""}>
                {TextMessage?.LoginPage?.Points2?.one}
                  <span onClick={() => router.push("/signup")}>{TextMessage?.LoginPage?.Points2?.two}</span>
                </p>
                <p className={findTamilCharacter(TextMessage?.LoginPage?.Heading)? styles.Tamilp : ""}>
                {TextMessage?.LoginPage?.Points2?.three}
                  <span onClick={() => router.push("/forgot-password")}>
                  {TextMessage?.LoginPage?.Points2?.four}
                  </span>
                </p>
              </div>
              <span className={findTamilCharacter(TextMessage?.LoginPage?.Heading)?styles.signupTamil: styles.signup}> {TextMessage?.LoginPage?.Points2?.five} </span>
            </div>

            <div className={styles.socialMedia}>
              <img onClick={() => window.location.replace(`${Config.BaseUrl}/connect/google`)} src="/assests/icons/google.png" alt="" />
              <BsFacebook onClick={() => window.location.replace(`${Config.BaseUrl}/connect/facebook`)} className={styles.fb} />
              {/* <img src="/assests/icons/instagram.png" alt="" /> */}
            </div>
          </form>
        </div>
      </div>
      {showSuccessMessage === true && (
        <Success
          heading={"Login Successfull"}
          text={TextMessage?.LoginPage?.Successfull}
          setShowSuccessMessage={setShowSuccessMessage}
          path={"/"}
        />
      )}
      {showErrorMessage === true && (
        <Error
          heading={"Failed to login!!"}
          text={errorMessage}
          setShowErrorMessage={setShowErrorMessage}
          path={""}
        />
      )}
    </>
  );
};

export default login;
