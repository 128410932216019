import { useRouter } from 'next/router'
import React from 'react'
import { MdClose } from 'react-icons/md'
import styles from '../styles/ErrorAndSuccess.module.css'
const Error = ({heading,text,path,setShowErrorMessage}) => {

const router  = useRouter();

  const close =()=>{
    setShowErrorMessage(false)

  }

  const goTo = ()=>{
    if(path===''){
    setShowErrorMessage(false)
return;
    }

    
    router.push(path)

  }



  return (
    <div className={styles.wrapper} >

<div className={styles.Error}>

<div className={styles.icon} onClick={close}>
          <MdClose  className={styles.iconBTN}  />
    </div>
<div  className={styles.contentWrapper}>

<div className={styles.img}>
<img src="/assests/icons/error.png" alt="" />
</div>
<div className={styles.contentError}>
<h1>{heading}</h1>
<p>{text} </p>
<button  onClick={goTo} >Continue</button>
</div>
</div>

</div>


    </div>
  )
}

export default Error