import { useRouter } from 'next/router'
import React from 'react'
import { MdClose } from 'react-icons/md'
import styles from '../styles/ErrorAndSuccess.module.css'




const Success = ({heading,setShowSuccessMessage,text,path}) => {
// const {heading} = props
const router = useRouter();



const close = ()=>{


  setShowSuccessMessage(false)
}

const goTo = ()=>{
  if(path===''){
    setShowSuccessMessage(false)
return;
    }
  
  router.push(path)

}


  return (
    <div className={styles.wrapper} >

<div className={styles.Error}>

<div className={styles.icon} onClick = {close} >
        <MdClose fontSize={20} />
    </div>
<div  className={styles.contentWrapper}>

<div className={styles.img}>
<img src="/assests/icons/success.png" alt="" />
</div>
<div className={styles.content}>
<h1>{heading}</h1>
<p>{text} </p>
<button onClick = {goTo}>Continue</button>
</div>
</div>

</div>


    </div>
  )
}

export default Success