import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { handleLang } from "../utils/Helper";
import LocaLizationData from "../data/LocalizationData.json"

export function localization(){
    const globalselectedlang = useSelector((state) => state.language.globalLang);
  const [TextMessage, setTextMessage] = useState("")
  // set global selected language
  useEffect(() => {
    const lang = handleLang();
    const language = JSON.parse(lang);
    if (globalselectedlang == "en" || language == "en") {
      setTextMessage(LocaLizationData.en)
    }
    if (globalselectedlang == "hi" || language == "hi") {
      setTextMessage(LocaLizationData.hi)
    }
    if (globalselectedlang == "ta" || language == "ta") {
      setTextMessage(LocaLizationData.ta)
    }
  }, [globalselectedlang]);

  return TextMessage
}